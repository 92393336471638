body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
.not-allowed {
  cursor: not-allowed;
}
.dTjIje {
  clip-path: unset !important;
  height: auto !important;
}

.navbar {
  background-image: linear-gradient( to right, rgb(136,78,211), rgb(16,20,128) ) !important;
}
.kvKGlQ {
  overflow-x: hidden !important;
  padding-left: inherit !important;
}
.hPIHhc {
  margin: auto !important;
  height: 370px !important;
}
.fEWFwa {
  clip-path: unset !important;
  height: auto !important;
}
.iyxIPT {
  height: 363px !important;
}
.hA-dlFA {
  padding-left: unset !important;
}
.MuiCardMedia-root {
  min-height: 160px;
}
.kvKGlQ {
  height: 370px !important;
}