.dashboardActiveStyle {
  background-color: #9f55ff;
  color: #fff;

  &:hover {
    background-color: #9f55ff;
    color: #fff;
  }
}

.dashboardNormalStyle {
  background: transparent;
  color: #9f55ff;

  &:hover {
    background-color: #9f55ff;
    color: #fff;
  }
}

.textActiveStyle {
  color: #ffffff;

  &:hover {
    color: #fff;
  }
}

// .textStyle {
//   color: #9f55ff;

//   &:hover {
//     color: #ffffff;
//   }
// }

.iconActiveStyle {
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

// .iconStyle {
//   color: #9f55ff;

//   &:hover {
//     color: #ffffff;
//   }
// }
